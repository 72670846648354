class CookieUtilsImpl {
    getCookie(cname) {
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(cname) == 0) {
            return c.substring(cname.length + 1, c.length);
          }
        }
        return "";
    }

    setCookieWithExpiry(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; Expires=" + expires + ";Path=/; Domain=invrs.com";
    }

    setCookie(cname, cvalue) {
        document.cookie = cname + '=' +cvalue + ';path=/; Domain=invrs.com';
    }

    deleteCookie(cname) {
        document.cookie = cname + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=invrs.com';
    }
  
}

const CookieUtils = new CookieUtilsImpl();
export default CookieUtils;
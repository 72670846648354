<template>
  <div class="container">
    <div class="contest-container">
      <h2 class="heading-secondary">INVRS 2022 Growth Contest</h2>
      <h3 class="heading-tertiary">Eligibility</h3>
      <p class="contest">
        The following individuals and their immediate family members are
        prohibited from participating in the INVRS 2022 Growth Contest: Verdant
        Analysis, Inc. employees and board members. Company Advisors, Partners
        and anyone with a material financial interest in the company are also
        prohibited from participating.
      </p>
      <p class="contest">Participants must be at least 18 years of age.</p>
      <p class="contest">To qualify for the contest, participants must:</p>
      <ul>
        <li class="contest">Have a free INVRS account,</li>
        <li class="contest">
          Create and publish a note on INVRS tagged with "Growth Contest 2022"
          by March 1, 2022,
        </li>
        <li class="contest">
          The submission MUST be a note and not a status post,
        </li>
        <li class="contest">
          The note must identify one, and only one, stock the participant
          believes will have the best price appreciation from the period
          starting on Feb 28th to December 31st, 2022,
        </li>
        <li class="contest">
          Your stock pick must trade on a major stock exchange in the US or
          Canada, specifically NYSE, NASDAQ, ASE, TSX, TSXV. Entries citing an
          OTC stock will be ignored. There are no market cap constraints.
        </li>
      </ul>
      <h3 class="heading-tertiary">Prizes</h3>
      <p class="contest">
        <strong>Best Thesis</strong>
        is awarded on March 14th, 2022. Total prize money is $1,000 CAD.
      </p>
      <p class="contest">
        <strong>Most Engagement</strong>
        is awarded on April 30th, 2022. Total prize money is $1,000 CAD.
      </p>
      <p class="contest">
        <strong>Best Return</strong>
        is awarded monthly throughout 2022 on the last business day of the month
        beginning in March 2022. Total prize money is $100 CAD per month.
      </p>
      <p class="contest">
        <strong>Bonus Award: 2 birds, 1 stone</strong>
        is awarded on January 1st, 2023. Total prize money is $2,000 CAD.
      </p>
      <p class="contest">
        <strong>Bonus Award: Trifecta</strong>
        is awarded on January 1st, 2023. Total prize money is $5,000 CAD.
      </p>
      <h3 class="heading-tertiary">
        Guidance on winning the best thesis award
      </h3>
      <p class="contest">
        <strong>Make it easy for the judges to read.</strong> Do not include
        filler or attempt to make the thesis longer than is necessary to make
        your point. There is no required word count. The judges prefer that you
        tell your story succinctly using good grammer.
      </p>
      <p class="contest">
        <strong>Use INVRS where you can.</strong> You are not restricted to the
        information in INVRS, you can bring in information from other sources.
        However, if you use information from another source when you could have
        used INVRS, it will count against you.
      </p>
      <p class="contest">
        <strong>Use visuals to support your work.</strong> If a graph, chart, or
        other image (especially if it is from INVRS) tells the story better than
        words, use it.
      </p>
      <p class="contest">
        <strong>Submit original work.</strong> There will be example theses for
        you to review and once people start to post, you will be able to read
        those too. Although there may be information in these Notes or in other
        published documents/information outside of INVRS that strengthens your
        thesis, you will only be given credit for original work. If you do draw
        on someone else's work, you should give them credit. Failure to cite the
        work of someone else may count against you.
      </p>
      <p class="contest">
        <strong>Recommended thesis structure.</strong> We recommend structuring
        your thesis submission as follows:
      </p>
      <p class="contest">
        <strong>Introduction:</strong> Include an opening paragraph introducing
        your pick and mention the reasons why you think it will perform well.
      </p>
      <p class="contest">
        <strong>Supporting paragraphs:</strong> Write a paragraph for each of
        the reasons you mentioned in your introduction. The purpose is to help
        the judges understand why each reason is important and will contribute
        to superior price performance.
      </p>
      <p class="contest">
        <strong>Conclusion:</strong> Summarize your evidence and restate your
        position.
      </p>
      <h3 class="heading-tertiary">
        Guidance on winning the most engagement award
      </h3>
      <p class="contest">
        Once you've submitted your thesis, get some action on it! You want
        people to like and comment on it. Invite your friends on to the platform
        to support your idea and share it on social media.
      </p>
      <p class="contest">We score your engagement as follows:</p>
      <p class="contest">Each like = 1 point</p>
      <p class="contest">Each comment under 20 characters = 1 point</p>
      <p class="contest">Each comment over 20 characters = 2 points</p>
      <p class="contest">
        Each time an INVRS user shares your post outside of the platform AND
        someone clicks the link = 5 points
      </p>
      <p class="contest">
        We count the engagements from the time you publish your Note to April
        15th. We announce the winner on April 30th.
      </p>
      <p class="contest">
        Note, comments that are nonsensical aren't scored and multiple comments
        by the same person score a maximum of 2 points.
      </p>
      <h3 class="heading-tertiary">
        Guidance on winning the best return award
      </h3>
      <p class="contest">
        This is a monthly prize awarded to the stock that experiences the
        largest price appreciation.
      </p>
      <p class="contest">
        At the end of the last business day of the month, we will post the INVRS
        advanced chart with all the submissions stocks graphed. The stock with
        the greatest percentage price increase is the winner for the month. The
        beginning date is March 1st, 2022.
      </p>
      <p class="contest">
        Prizes are awarded monthly from March 2022 to December 2022.
      </p>
      <p class="contest">
        <strong>Negative returns:</strong> In the case of all stocks posting
        negative returns, the one with the least negative return is the winner.
      </p>
      <p class="contest">
        <strong>Multiple entries:</strong> In the case of more than one entry
        for the best return stock, prizes will be awarded as follows:
      </p>
      <p class="contest">
        The person who first posted the note about the stock wins $100. All
        other entries share $50 evenly. Get your entry in early!
      </p>
      <p class="contest">
        <strong>Tie:</strong> In the unusual case of a tie - two different
        stocks with identical best returns - both submissions will earn $100. If
        there are also multiple entries for the same stock, the multiple entry
        rule is applied after the tie rule.
      </p>
      <h3 class="heading-tertiary">
        Guidance on bonus award - 2 birds, 1 stone
      </h3>
      <p class="contest">
        You must have won the best thesis or most engagement award to qualify
        for this award. If you win either best thesis OR most engagement AND win
        best return on December 31st, 2022, you win this bonus of $2,000.
      </p>
      <h3 class="heading-tertiary">Guidance on bonus award - trifecta</h3>
      <p class="contest">
        You must have won BOTH the best thesis and most engagement awards to
        qualify for this award. If you win best thesis AND most engagement AND
        win best return on December 31st, 2022, you win this bonus of $5,000.
      </p>
      <p class="contest">
        If you win the Trifecta award you cannot also claim the 2 Birds, 1 Stone award.
      </p>
      <h3 class="heading-tertiary">Contest Judging</h3>
      <p class="contest">
        The best thesis award is judged by Robin Speziale, best-selling author
        of Capital Compounders and Market Masters, and Jennifer Cameron, CPA
        CFA, CEO of Verdant Analysis, Inc. In the event of a split decision, the
        participants will share the award equally.
      </p>
      <p class="contest">
        <strong>Split decision in best thesis award:</strong> The $1,000 prize
        is shared equally between both participants and both now have the chance
        to win the Bonus Awards.
      </p>
      <p class="contest">
        <strong>Additional ties in the bonus awards:</strong> In the rare event
        that a split decision in the Best Thesis award leads to ties in the Two
        Birds, One Stone or Trifecta Awards, participants will share the
        bonus(es) equally.
      </p>
      <h3 class="heading-tertiary">Restrictions on multiple entries</h3>
      <p class="contest">
        Multiple entries that involve an actual thesis are permitted. Multiple
        entries that just mention a stock to game the Best Return prize will be
        deleted
      </p>
      <h3 class="heading-tertiary">
        Prize winner notification and delivery of prize
      </h3>
      <p class="contest">
        Winners will be notified by email. Please confirm your email address
        when you sign up to ensure we can get the prize to you. Once notified,
        we arrange delivery via EFT, PayPal or other mutually agreeable method.
      </p>
      <h3 class="heading-tertiary">Unclaimed awards</h3>
      <p class="contest">
        Verdant Analysis, Inc. bears no responsibility for distributing awards
        that are not claimed within one month of announcement. If an award is
        not claimed, we will announce the second place participant as the new
        winner.
      </p>
      <h3 class="heading-tertiary">Chance of winning</h3>
      <p class="contest">
        This is a skills-based contest, not a game of chance. The more work you
        put into your submission, the greater your chance of winning. That said,
        the number of participants will impact your chances of winning.
      </p>
      <h3 class="heading-tertiary">Number of participants:</h3>
      <p class="contest">
        There is currently no cap on the number of participants permitted into
        the contest. Verdant Analysis, Inc. reserves the right to cap the number
        of participants at any time without prior warning and to close the
        contest to new entrants.
      </p>
      <h3 class="heading-tertiary">Currency of awards:</h3>
      <p class="contest">
        All awards are in Canadian dollars. If you wish to be paid in USD, we
        will arrange the conversion at market rates at the time of prize award.
      </p>
      <h3 class="heading-tertiary">FAQ:</h3>
      <p class="contest">
        Q: Can I make changes to my submission after I post my note?
      </p>
      <p class="contest">
        A: Yes, you can make edits to your submission up until the thesis due
        date, however, it may affect your place in line for the best returns
        award.
      </p>
      <p class="contest">
        Q: Someone else won the best thesis award but we chose the same stock.
        Why didn't I win too?
      </p>
      <p class="contest">
        A: Two or more people may select the same stock and one of them may win
        the Best Thesis award. The best thesis is awarded on the quality of the
        reasoning behind the decision, not on the stock itself.
      </p>
      <p class="contest">
        Q: If two or more people select the same stock and one of them wins the
        Best Thesis award, does the award winner automatically get the Best
        Return if the stock has the best return in a particular month?
      </p>
      <p class="contest">
        A: If two or more people select the same stock that wins the Best
        Returns award, the person who submitted their idea first gets $100. The
        other people will share $50 equally. See GUIDANCE ON BEST RETURN AWARD.
      </p>
    </div>
  </div>
</template>

<style scoped>
.contest {
  margin-bottom: 3.2rem;
  line-height: 1.3;
}

.contest-container {
  padding: 14.4rem 12rem;
}

.heading-tertiary {
  margin-bottom: 1.6rem;
  line-height: 1.2;
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .contest-container {
    padding: 14.4rem 6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .contest-container {
    padding: 12.8rem 2.4rem;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .contest-container {
    padding: 12.8rem 1.2rem;
  }
}
</style>
